import { useCallback, useEffect, useMemo } from 'react';

import { InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ReactComponent as Logo } from '@quick/assets/logos/quick.svg';

import { EmptyLayout } from '@/components/core';
import { useCountry } from '@/layers/core/deal/services/CountryContext';
import { useFetch } from '@/layers/core/deal/services/useFetch';
import { usePrefetch } from '@/layers/core/deal/services/usePrefetch';
import { getI18nProps } from '@/lib/getStatic';
import i18nextConfig from '@/next-i18next.config';

//#region Page Setup
export async function getStaticProps() {
  const lang = i18nextConfig.i18n.defaultLocale;
  return {
    props: {
      ...(await getI18nProps({ params: { lang } }, ['common', 'dl', 'demand'])),
    },
  };
}
//#endregion

const Home = ({}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const router = useRouter();
  const { lang, id } = useCountry();

  const prefetchable = useMemo(() => [`/${lang}`], [lang]);
  usePrefetch(prefetchable);

  const next = useCallback(
    () =>
      router.replace(
        { pathname: `/[lang]`, query: { ...router.query, lang } },
        undefined,
        {
          shallow: true,
        }
      ),
    [lang, router]
  );
  // Populate SWR cache for next page
  useFetch({
    url: `/services/country/lines/${id}`,
    auth: true,
    params: { user_id: undefined },
  });

  useEffect(() => {
    // It should be handled by onAnimationEnd, but in case there is a buggy browser
    const t = setTimeout(next, 10_000);
    return () => clearTimeout(t);
  }, [next]);

  return (
    <>
      <Head>
        <title key="title">Quick App</title>
      </Head>
      <div
        className="animate-in fade-in-0 flex h-full w-full items-center justify-center
          duration-1000"
        onAnimationEnd={next}
      >
        <Logo height={96} />
      </div>
    </>
  );
};

Home.Layout = EmptyLayout;

export default Home;
