import React from 'react';

import { useParams } from 'next/navigation';

import clsx from 'clsx';

import { Alert, DarkModeSwitcher } from '@quick/components';

import { LanguageSwitcher } from '@/app/i18n/switcher';
import { NoSSR } from '@/components/core/NoSSR';
import { useGeneralStore } from '@/layers/core/deal/repositories/generalStore';
import styles from '@/styles/utils.module.css';

export function EmptyLayout({ children }: { children: React.ReactNode }) {
  const queryParams = useParams<{ lang: string }>();
  const lang = queryParams?.['lang'];
  const alert = useGeneralStore((store) => store.alert);
  return (
    <div
      className={clsx(
        `relative h-full w-full overflow-y-auto transition-colors dark:bg-gray-800
        dark:text-gray-300`,
        styles.top_safe
      )}
      id="MainContainer"
    >
      <div className="fixed right-0 z-10 p-2 text-2xl">
        <div className="flex items-center gap-2">
          {lang && <LanguageSwitcher className="text-base" />}
          <NoSSR>
            <DarkModeSwitcher />
          </NoSSR>
        </div>
      </div>
      {children}
      {alert && <Alert {...alert} className="z-100" />}
    </div>
  );
}
