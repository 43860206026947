import { useEffect } from 'react';

import { useRouter } from 'next/router';

export const usePrefetch = (paths: string[]) => {
  const router = useRouter();
  useEffect(() => {
    if (!router.isReady) return;
    paths.forEach((path) => router.prefetch(path));
  }, [router.isReady, paths, router]);
};
