import type { UrlObject } from 'url';

import Link from 'next/link';
import { useParams, usePathname, useSearchParams } from 'next/navigation';

import clsx from 'clsx';

import { ReactComponent as Lang } from '@/assets/lang.svg';
import i18nextConfig from '@/next-i18next.config';

const languages = i18nextConfig.i18n.locales;
const regexLanguages = new RegExp(`^/(${languages.join('|')})`);

export function LanguageSwitcher({ className }: { className?: string }) {
  const searchParams = useSearchParams();
  const params = useParams();
  const path = usePathname();

  const currentLang = (params?.lang ??
    i18nextConfig.i18n.defaultLocale) as string;

  return (
    <div
      className={clsx(
        `group/lang grid grid-cols-1 grid-rows-1 items-center gap-2 text-gray-300
        focus-within:text-gray-800 hover:text-gray-800 dark:hover:text-gray-500`,
        className
      )}
      role="button"
      tabIndex={0}
    >
      <Lang
        className="col-start-1 row-start-1 origin-left transition-transform
          group-focus-within/lang:scale-x-0 group-hover/lang:scale-x-0"
      />
      <div
        className="col-start-1 row-start-1 flex origin-right scale-x-0 transform items-center gap-1
          transition-transform group-focus-within/lang:scale-x-100
          group-hover/lang:scale-x-100"
      >
        {languages.map((lang) => {
          const href: UrlObject = {
            pathname: path?.replace(regexLanguages, `/${lang}`),
            query: searchParams?.toString(),
          };
          return (
            <LanguageSwitchLink
              key={lang}
              lang={lang}
              currentLang={currentLang}
              href={href}
            />
          );
        })}
      </div>
    </div>
  );
}

function LanguageSwitchLink({
  lang,
  href,
  currentLang,
}: {
  lang: string;
  href: UrlObject;
  currentLang: string;
}) {
  return (
    <span>
      <Link
        href={href}
        replace={true}
        // shallow={shallow}
        className={clsx(
          `align-middle uppercase text-gray-300 hover:font-semibold hover:text-gray-800
          dark:hover:text-gray-500`,
          // TODO: Change this when hosting strategy changes
          lang === currentLang &&
            'shadow-[inset_0px_-6px_0px_-3px_theme(colors.brand.DEFAULT)]'
        )}
      >
        {lang}
      </Link>
    </span>
  );
}

export default LanguageSwitcher;
